<template>
  <div class="r-dd">
    <div class="stars">
      <span
        :class="{ selected: starValue >= i }"
        v-for="i in 5"
        :key="i"
        @click="starValue = i"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Star",
  props: {
    star: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    starValue: {
      get() {
        return this.star;
      },
      set(value) {
        this.$emit("starUpdate", value);
      }
    }
  }
};
</script>

<style scoped></style>
